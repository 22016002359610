<template>
  <div
    class="input_custom"
    :class="[
      {
        [attrs.className as string]: true,
        focus: hasFocus,
        disabled: disabled,
        error: error,
        colorPack: colorPack
      }
    ]"
    :data-a2s-obj-name="attrs.rest['data-a2s-obj-name']"
  >
    <input
      :id="id"
      :type="type"
      :name="id"
      :maxlength="maxlength"
      :value="modelValue"
      :disabled="disabled"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :required="required"
      v-bind="attrs.rest"
      @input="handleInput"
      @focusin="hasFocus = true"
      @focusout="hasFocus = false"
    />
    <atoms-c-icon
      v-if="clear && modelValue.length"
      icon-name="clear"
      @click.stop="emit('update:modelValue', '')"
    />
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  id: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: null
  },
  maxlength: {
    type: String,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  value: {
    type: [String, Number],
    default: null
  },
  required: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  autocomplete: {
    type: String,
    default: null
  },
  clear: {
    type: Boolean,
    default: false
  },
  colorPack: {
    type: String,
    default: 'blue' // or gray
  }
})
defineOptions({
  inheritAttrs: false
})

const hasFocus = ref<boolean>(false)
const emit = defineEmits(['update:modelValue'])

const rawAttrs = useAttrs()
const attrs = computed(() => {
  const { class: className, ...rest } = rawAttrs

  return {
    className,
    rest
  }
})
const handleInput = (e: Event) => {
  const target = e.target as HTMLInputElement
  if (props.maxlength) {
    const value = target.value
    if (parseInt(props.maxlength) < value.length) {
      target.value = value.slice(0, parseInt(props.maxlength))
    }
  }
  emit('update:modelValue', target.value)
}
</script>

<style lang="scss" scoped>
.input_custom {
  display: flex;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 8px;
  background-color: $bg-l-blue1;
  border: 2px solid $bg-l-blue1;
  > input {
    flex: 1;
    width: 0; // for browser bug
    padding: 0;
    border: 0;
    background-color: transparent;
    font-size: 16px;
    font-weight: 400;

    &::placeholder {
      color: $gray1;
    }
  }
  &:hover {
    background-color: $blue3;
    border-color: $blue3;
  }
  &.focus {
    border-color: $blue1;
    background-color: $blue3;
  }
  &:not(.focus).error {
    border-color: $sys-red0;
    > input {
      color: $sys-red0;
    }
  }
  &.disabled {
    background-color: #e9eef4;
  }
  .icon_clear {
    cursor: pointer;
  }
  &.gray {
    background-color: #f9f9f9;
    border-color: #f9f9f9;
    &:hover {
      background-color: #f2f2f2;
      border-color: #f2f2f2;
    }
    &.focus {
      background-color: #f2f2f2;
      border-color: $blue1;
    }
  }
}
@media (max-width: 999px) {
  .input_custom {
    padding: 12px;
    > input {
      font-size: 15px;
    }
  }
}
</style>
